<template>
  <teleport to="#module">
    <Module ref="module" class="module">
      <div el="list-column-between">
        <h3 el>Delete User</h3>
        <span class="material-icons" @click="$refs.module.toggle()" el="icon"
          >close</span
        >
      </div>
      <div el="list md">
        <p el>Are you sure you want to delete this user?</p>
        <div el="list-column-between">
          <div></div>
          <div>
            <div el="btn" @click="deleteUser(selectedUser.id)">Delete User</div>
          </div>
        </div>
      </div>
    </Module>
  </teleport>
  <div el="list" style="--gap:16px;max-width:1200px;">
    <div el="list-column-between xxsm" class="v-center">
      <div el="tabs-underline">
        <div
          :class="{ active: users.filters.approvedAccount }"
          @click="changeFilter('approvedAccount')"
        >
          Approved
        </div>
        <div
          :class="{ active: users.filters.pending }"
          @click="changeFilter('pending')"
        >
          Pending
        </div>
        <div
          :class="{ active: users.filters.admin }"
          @click="changeFilter('admin')"
        >
          Admins
        </div>
      </div>
      <span
        class="material-icons"
        title="Export Users: CSV"
        el="icon"
        @click="downloadUserCSV"
        >save_alt</span
      >
    </div>
    <div :class="{ 'dynamic-table1': true, selected: selectedUser != null }">
      <div style="padding:20px;">
        <!-- <div el="indeterminate-progress"></div> -->
        <div el="input-list">
          <label el="input" class="search">
            <input
              v-model="users.search"
              type="text"
              name="input"
              placeholder="Search..."
              el
            />
            <span class="material-icons">search</span>
          </label>
        </div>
      </div>
      <div v-if="selectedUser == null"></div>
      <div
        v-else
        style="grid-row: span 2;display: grid;grid-template-rows: max-content 1fr;overflow:hidden;"
      >
        <div
          el="list"
          style="--gap:8px;padding-top:24px;--border-bottom: 1px solid #ccc;--hover-border-bottom:var(--border-bottom);--pressed-border-bottom:var(--border-bottom);"
        >
          <!-- <div el="indeterminate-progress"></div> -->
          <div
            el="list-column-between"
            style="padding:0 24px;"
            class="v-center"
          >
            <div el="list-column" class="v-center">
              <h4 el>
                {{ selectedUser.firstName }} {{ selectedUser.lastName }}
              </h4>
              <!-- <span class="material-icons" el="icon-2ry">edit</span> -->
            </div>
            <div el="list-column" class="v-center" style="--gap: 16px;">
              <div
                el="btn-2ry"
                v-if="!selectedUser.approvedAccount"
                @click="setAccess(selectedUser.id, true)"
              >
                Grant Access
              </div>
              <div
                el="btn-2ry"
                v-else
                @click="setAccess(selectedUser.id, false)"
              >
                Revoke Access
              </div>
              <!-- <span class="material-icons" el="icon-2ry" @click="deselectUser">close</span> -->
            </div>
          </div>
          <div
            el="list-column-between"
            style="padding:0 24px;padding-bottom:16px;"
            class="v-center"
          >
            <div el="list-column" class="v-center">
              <p el>{{ selectedUser.email }}</p>
              <!-- <div el="flat-btn-2ry" style="padding: 0;">
                                <span class="material-icons">radio_button_unchecked</span>
                                <span>Select All</span>
                            </div> -->
            </div>
            <div el="list-column xxsm" class="v-center">
              <span
                class="material-icons"
                el="icon"
                @click="users.editSelectedUser = true"
                v-if="!users.editSelectedUser"
                >edit</span
              >
              <span
                class="material-icons"
                el="icon"
                @click="$refs.module.toggle()"
                >delete</span
              >
              <!-- <div el="flat-btn-2ry">
                                <span class="material-icons">file_copy</span>
                                <span>Duplicate</span>
                            </div> -->
              <!-- <span class="material-icons" el="icon-2ry">view_module</span>
                            <span class="material-icons" el="icon-2ry">view_list</span> -->
            </div>
          </div>
        </div>
        <div style="overflow: auto;">
          <!-- User Details -->
          <div el="list sm" style="padding:24px;">
            <p el="bold" v-if="selectedUser.approvedAccount">Permissions</p>

            <div v-if="selectedUser.approvedAccount">
              <div
                el="toggle"
                :class="{ active: selectedUser.permissions.includes('admin') }"
                @click="toggleAdmin"
              >
                admin
              </div>
            </div>
            <div el="list xxsm" v-if="!users.editSelectedUser">
              <p el><b>Phone:</b> {{ selectedUser.phone }}</p>
              <p el><b>Email:</b> {{ selectedUser.email }}</p>
              <p el><b>Address:</b> {{ selectedUser.address }}</p>
              <p el><b>Account Type:</b> {{ selectedUser.accountType }}</p>
              <p el>
                <b>Created on:</b> {{ formatDate(selectedUser.createdOn) }}
              </p>
              <p
                el
                v-if="selectedUser.notes != null && selectedUser.notes != ''"
              >
                <b>Notes:</b> {{ selectedUser.notes }}
              </p>
            </div>
            <div el="list xxsm" v-else>
              <div el>
                <b>First Name: </b
                ><input
                  type="text"
                  class="input-text"
                  v-model.trim="selectedUser.firstName"
                />
              </div>
              <div el>
                <b>Last Name: </b
                ><input
                  type="text"
                  class="input-text"
                  v-model.trim="selectedUser.lastName"
                />
              </div>
              <div el>
                <b>Phone: </b
                ><input
                  type="text"
                  class="input-text"
                  v-model.trim="selectedUser.phone"
                />
              </div>
              <p el>
                <b>Address: </b
                ><input
                  type="text"
                  class="input-text"
                  v-model.trim="selectedUser.address"
                />
              </p>
              <p el>
                <b>Account Type: </b>
                <select v-model="selectedUser.accountType" class="input-select">
                  <option value="resident">Resident</option>
                  <option value="renter">Renter</option>
                </select>
              </p>
              <p el>
                <b>Notes: </b
                ><input
                  type="text"
                  class="input-text"
                  v-model.trim="selectedUser.notes"
                />
              </p>

              <div el="list-column xxsm">
                <div el="btn-2ry" @click="users.editSelectedUser = false">
                  Cancel
                </div>
                <div el="btn" @click="updateUser">Save</div>
              </div>
            </div>
            <!-- <div el="list xxsm">
                            <div el="list-column">
                                
                            </div>
                        </div> -->
          </div>
        </div>
      </div>
      <div style="overflow: auto;">
        <table el="table">
          <colgroup>
            <col style="width:150px;" />
            <col />
          </colgroup>
          <thead>
            <th>Name</th>
            <th>Email</th>
          </thead>
          <tbody>
            <tr
              v-for="item in filteredUsers"
              :key="item"
              @click="selectUser(item.email)"
              :class="{ active: users.selected == item.email }"
            >
              <td>{{ item.firstName }} {{ item.lastName }}</td>
              <td>{{ item.email }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { db } from "../firebase";
import Module from "@/components/Module.vue";
import Papa from "papaparse";

export default {
  name: "Messages",
  props: ["title"],
  components: {
    Module,
  },
  data() {
    return {
      users: {
        filters: {
          approvedAccount: true,
          admin: false,
          pending: false,
        },
        selected: null,
        editSelectedUser: false,
        search: "",
        //   filters:{

        //   },

        list: [
          {
            firstName: "First",
            lastName: "Last",
            email: "name@email.com",
            address: "address",
            phone: "phone",
            approvedAccount: false,
            permissions: [],
            role: "",
            createdOn: Date.now(),
          },
        ],
      },
    };
  },
  methods: {
    selectUser(email) {
      this.users.selected = email;
    },
    deselectUser() {
      this.users.selected = null;
    },
    // firebase methods
    getUserData() {
      var x = this;
      let users = db.collection("users"),
        query = null;

      if (x.users.filters.admin) {
        query = users.where("permissions", "array-contains", "admin");
      } else {
        query = users.where(
          "approvedAccount",
          "==",
          x.users.filters.approvedAccount
        );
      }

      x.users.list = [];
      if(x.users.filters.approvedAccount == true || x.users.filters.admin == true){
        query
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            var data = doc.data();
            data.id = doc.id;
            x.users.list.push(data);
            //console.log(doc.id, " => ", doc.data());
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      }
      // console.log(query);
    },
    setAccess(id, val) {
      var x = this;
      db.collection("users")
        .doc(id)
        .update({
          approvedAccount: val,
        })
        .then(() => {
          console.log("Document successfully updated!");
          x.getUserData();
        });
    },
    toggleAdmin() {
      var x = this;
      let userPer = x.selectedUser.permissions;
      let i = userPer.indexOf("admin");
      if (i >= 0) {
        userPer.splice(i, 1);
      } else {
        userPer.push("admin");
      }
      db.collection("users")
        .doc(x.selectedUser.id)
        .update({
          permissions: userPer,
        })
        .then(() => {
          console.log("Document successfully updated!");
          x.selectedUser.permissions = userPer;
          // x.getUserData();
        });
    },
    updateUser() {
      var x = this;
      db.collection("users")
        .doc(this.selectedUser.id)
        .update({
          firstName: x.selectedUser.firstName,
          lastName: x.selectedUser.lastName,
          phone: x.selectedUser.phone,
          address: x.selectedUser.address,
          accountType: x.selectedUser.accountType,
          notes: x.selectedUser.notes || "",
        })
        .then(() => {
          x.users.editSelectedUser = false;
          console.log("Document successfully updated!");
          // x.getPostsData();
        });
    },
    deleteUser(uid) {
      var x = this;
      db.collection("users")
        .doc(uid)
        .delete()
        .then(() => {
          x.$refs.module.toggle();
          x.getUserData();
        })
        .catch((error) => {
          alert(error);
          console.error("Error removing document: ", error);
        });
    },
    formatDate(date) {
      let d = new Date(date);
      return d.toLocaleString("en-US");
    },
    changeFilter(val) {
      // switch(val){
      //   case 'admin':
      //     users.filters.admin = true;
      //     break;
      //   case 'approvedAccount':
      //     users.filters.approvedAccount = true;
      //     break;
      // }
      this.users.filters.admin = false;
      this.users.filters.approvedAccount = false;
      this.users.filters.pending = false;

      this.users.filters[val] = true;
      this.getUserData();
    },
    downloadUserCSV() {
      let x = this;
      let data = x.users.list;
      let csvData = data.map((item) => {
        return {
          "First Name": item.firstName,
          "Last Name": item.lastName,
          Email: item.email,
          Phone: item.phone,
          Address: item.address,
          "Account Type": item.accountType,
          "Approved Account": item.approvedAccount,
          Role: item.role,
          Permissions: item.permissions,
          "User ID": item.id,
          "Created On": x.formatDate(item.createdOn),
        };
      });
      let csv = Papa.unparse(csvData);
      // console.log(csv);
      x.downloadCSV("users.csv", csv);
    },
    downloadCSV(filename, data) {
      let blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      let link = document.createElement("a");
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  // watch: {
  //   "users.filters.approvedAccount"() {
  //     this.getUserData();
  //   },
  //   "users.filters.admin"() {
  //     this.getUserData();
  //   },
  // },
  computed: {
    selectedUser() {
      if (this.users.selected != null) {
        return _.find(this.users.list, { email: this.users.selected });
        //   return _.filter(this.users.list, { 'id': this.users.selected });
      } else {
        return null;
      }
    },
    filteredUsers() {
      var x = this;
      return _.filter(this.users.list, function(item) {
        return _.includes(
          _.lowerCase(item.firstName + " " + item.lastName + " " + item.email),
          x.users.search
        );
      });
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>
<style lang="scss">
.input-text,
.input-select {
  font-size: var(--font-p);
  font-weight: var(--font-regular);
  padding: 4px;
  border-radius: 3px;
  border: 0;
  background-color: #f1f1f1;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
[el="tabs"] {
  user-select: none;
}
[el="table"],
[el|="table"] {
  --control-border: 0px;
}
[el="table"] tbody tr {
  height: 46.4px;
  /* content-visibility:auto;
    contain-intrinsic-size:1px 46.4px; */
}
[el="table"] tr:hover,
[el|="table"] tr:hover,
[el="table"] tr.active {
  /* box-shadow: 0 0 0 1px var(--color-sky); */
  outline: 1px solid var(--color-sky);
  outline-offset: -1px;
}
[el="table"] tr.active {
  background-color: var(--color-sky-5);
}
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
thead th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 1px 0 var(--color-25);
}
.table-action thead th {
  box-shadow: 0 1px 0 var(--color-25), 0 1px 0 var(--color-25) inset;
}
.dynamic-table1 {
  display: grid;
  background: #ccc;
  height: 550px;
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-template-rows: max-content 1fr;
  grid-gap: 1px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.dynamic-table1.selected {
  grid-template-columns: 1fr 750px;
}
.dynamic-table1 > * {
  background: #fff;
  position: relative;
}
.search .material-icons {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-40);
  transition: 0.2s;
  -webkit-text-fill-color: currentColor;
}
.search:hover .material-icons,
.search:focus .material-icons {
  color: var(--color-sky);
}
.close {
  color: var(--color-40);
  -webkit-text-fill-color: currentColor;
  cursor: pointer;
  transition: 0.2s;
}
.close:hover {
  color: var(--color-sky);
}
.v-center {
  align-items: center;
}
tr.bold > td {
  font-weight: var(--font-medium);
}
.table-action th:first-child,
.table-action tr td:first-child {
  padding-left: 54px;
}
.table-action tr td:first-child {
  position: relative;
}
.table-action th:first-child .material-icons,
.table-action tr td:first-child .material-icons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
}

.thumbnail {
  width: 100%;
  height: 90px;
  object-fit: contain;
  opacity: 0.07;
  object-position: center;
}
.status {
  --size: 20px;
  height: var(--size);
  width: var(--size);
  display: inline-block;
  position: absolute;
  transform: translateY(-50%) translateX(-100%);
}
.text-right {
  text-align: right;
}
.ce-header {
  font-weight: bold;
  font-size: 1.5rem;
}
</style>
