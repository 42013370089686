<template>
  <teleport to="#module">
    <Module ref="module" class="module">
      <div el="list-column-between">
        <h3 el>{{ module.section }}</h3>
        <span class="material-icons" @click="$refs.module.toggle()" el="icon"
          >close</span
        >
      </div>
      <div el="list md" v-if="module.section == 'New Folder'">
        <input type="text" placeholder="Folder name" v-model="module.folder" />
        <div el="list-column-between">
          <div></div>
          <div>
            <div
              el="btn"
              @click="
                createFolder(module.folder);
                $refs.module.toggle();
              "
            >
              Create Folder
            </div>
          </div>
        </div>
      </div>
    </Module>
  </teleport>
  <ContextMenu :display="showContextMenu" ref="menu" @click="closeContextMenu">
    <div v-if="contextMenu.type == 'file'">
      <div
        @click="previewFile(contextMenu.data.fullPath)"
        class="option"
        v-if="contextMenu.data != null && storage.selected.length == 1"
      >
        <span class="material-icons">visibility</span>
        <div>Preview</div>
      </div>
      <div @click="downloadSelected" class="option">
        <span class="material-icons">save_alt</span>
        <div>Download</div>
      </div>
      <div @click="deleteSelected" class="option" v-if="isAdmin">
        <span class="material-icons">delete</span>
        <div>Delete</div>
      </div>
      <div
        @click="copyFileLink(contextMenu.data.fullPath)"
        class="option"
        v-if="isAdmin"
      >
        <span class="material-icons">link</span>
        <div>Copy Link</div>
      </div>
    </div>
    <div v-if="contextMenu.type == 'folder' && isAdmin">
      <div @click="deleteSelected" class="option">
        <span class="material-icons">delete</span>
        <div>Delete</div>
      </div>
    </div>
  </ContextMenu>
  <div
    el="list md"
    @click="closeContextMenu"
    style="min-height: calc(100vh - 220px)"
  >
    <div v-show="isAdmin" el="list-column xsm">
      <div el="btn" @click="toggleModule('New Folder')">Create Folder</div>
      <label for="file-selector" class="custom-file-upload" el="btn-2ry">
        <span class="material-icons">upload</span>
      </label>
      <input type="file" id="file-selector" multiple style="display: none" />
    </div>
    <div el="list-column-between">
      <div class="breadcrumbs">
        <div
          v-for="(item, index) in pathArray"
          :key="item"
          @click="visitPath(index + 1)"
        >
          {{ item }}
        </div>
      </div>
      <div el="list-column xsm" class="v-center" v-if="numFilesSelected > 0">
        <div el="link">Deselect ({{ numFilesSelected }})</div>
        <div @click="downloadSelected" el="icon">
          <span class="material-icons">save_alt</span>
          <!-- <div>Download</div> -->
        </div>
      </div>
    </div>
    <div class="folders" v-if="storage.folders.length > 0">
      <div
        v-for="item in storage.folders"
        :key="item"
        @click.stop="listFiles(item.fullPath)"
        :class="{ active: storage.selected.includes(item.fullPath + '/.dir') }"
        @contextmenu.prevent="openContextMenu($event, 'folder', item)"
        :title="item.name"
      >
        <span class="material-icons">folder</span>
        {{ item.name }}
      </div>
    </div>
    <div id="files" class="files" :keyup="deleteSelected">
      <div
        v-for="item in storage.files"
        :key="item"
        @click.stop="selectFile(item.fullPath)"
        :class="{ active: storage.selected.includes(item.fullPath) }"
        @contextmenu.prevent="openContextMenu($event, 'file', item)"
        :title="item.name"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
// import _ from 'lodash'
import { storage } from "../firebase";
import ContextMenu from "@/components/ContextMenu.vue";
import Module from "@/components/Module.vue";
import _ from "lodash";

export default {
  name: "Files",
  //   props:['title'],
  components: {
    ContextMenu,
    Module,
  },
  data() {
    return {
      showContextMenu: false,
      contextMenu: {
        type: "file",
        data: null,
      },
      module: {
        folder: "",
        section: "New Folder",
      },
      storage: {
        timers: {
          upload: null,
          delete: null,
        },
        currentPath: "",
        ref: "shared-files",
        selected: [],
        search: "",
        folders: [],
        files: [],
        uploads: [],
      },
    };
  },
  methods: {
    toggleModule(section) {
      this.module.section == section;
      this.$refs.module.toggle();
    },
    openContextMenu(e, type, item = null) {
      this.contextMenu.type = type;
      switch (type) {
        case "folder":
          this.storage.selected = [];
          this.selectFolder(item.fullPath);
          break;
        case "file":
          this.selectFile(item.fullPath, true);
          break;
      }
      this.contextMenu.data = item;
      this.$refs.menu.open(e);
    },
    closeContextMenu() {
      this.$refs.menu.close();
      this.storage.selected = [];
    },
    async createFolder(name) {
      var path = this.storage.currentPath + "/" + name + "/.dir";
      await storage.ref().child(path).put();
      this.listFiles(this.storage.currentPath);
    },
    visitPath(index) {
      var path = this.pathArray.slice(0, index).join("/");
      this.listFiles(path);
    },
    listFiles(path = null) {
      var x = this;
      if (path == null) {
        path = x.storage.ref;
      }
      x.closeContextMenu();
      x.storage.currentPath = path;
      x.storage.selected = [];
      // Create a reference under which you want to list
      var listRef = storage.ref().child(path);
      // var listRef = storageRef.child('shared-files/uid');
      // Reset list
      x.resetList();
      // Find all the prefixes and items.
      listRef
        .listAll()
        .then((res) => {
          res.prefixes.forEach((folderRef) => {
            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
            // console.log('folderRef', folderRef);
            x.storage.folders.push(folderRef);
          });
          res.items.forEach((itemRef) => {
            // All the items under listRef.
            // console.log('itemRef', itemRef);
            if (itemRef.name != ".dir") {
              x.storage.files.push(itemRef);
            }
          });
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error);
        });
    },
    resetList() {
      this.storage.folders = [];
      this.storage.files = [];
    },
    selectFile(path, val = false) {
      var x = this;
      var i = x.storage.selected.indexOf(path);
      if (i >= 0) {
        if (x.storage.selected.length == 1) {
          x.previewFile(path);
        } else if (!val) {
          x.storage.selected.splice(i, 1);
        }
      } else {
        x.storage.selected.push(path);
      }
    },
    selectFolder(path) {
      var x = this;
      path = path + "/.dir";
      var i = x.storage.selected.indexOf(path);
      if (i >= 0) {
        x.storage.selected.splice(i, 1);
      } else {
        x.storage.selected.push(path);
      }
    },
    deleteFile(path) {
      var x = this;
      var file = storage.ref().child(path);
      file
        .delete()
        .then(() => {
          console.log('deleted "' + path + '"');
          clearTimeout(x.storage.timers.delete);
          x.storage.timers.delete = setTimeout(() => {
            x.listFiles(x.storage.currentPath);
          }, 500);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteSelected() {
      var x = this;
      x.storage.selected.forEach((item) => {
        x.deleteFile(item);
      });
    },
    downloadSelected() {
      var x = this;
      x.storage.selected.forEach((item) => {
        x.downloadFile(item);
      });
    },
    downloadFile(path) {
      var x = this;
      storage
        .ref()
        .child(path)
        .getDownloadURL()
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'

          // This can be downloaded directly:
          var xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = () => {
            var blob = xhr.response;
            console.log(blob);
            x.downloadBlob(blob, path.split("/").pop());
          };
          // xhr.onload = (event) => {
          //     var blob = xhr.response;
          // };
          xhr.open("GET", url);
          xhr.send();

          // Or inserted into an <img> element
          // var img = document.getElementById('img');
          // img.setAttribute('src', url);
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
        });
    },
    downloadBlob(blob, name) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob)
        return window.navigator.msSaveOrOpenBlob(blob);

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = data;
      link.download = name;

      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    },
    uploadFile(file, name) {
      var x = this;
      var path = this.storage.currentPath;
      var uploadTask = storage
        .ref()
        .child(path + "/" + name)
        .put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(progress);

          // x.storage.uploads[name] = {
          //     label:name,
          //     progress:progress
          // };
          // console.log(x.storage.uploads);
          // switch (snapshot.state) {
          // case storage.TaskState.PAUSED: // or 'paused'
          //     console.log('Upload is paused');
          //     break;
          // case storage.TaskState.RUNNING: // or 'running'
          //     console.log('Upload is running');
          //     break;
          // }
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          clearTimeout(x.storage.timers.upload);
          x.storage.timers.upload = setTimeout(() => {
            x.listFiles(path);
          }, 1000);

          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at", downloadURL);
          });
        }
      );
    },
    previewFile(path) {
      storage
        .ref()
        .child(path)
        .getDownloadURL()
        .then((url) => {
          window.open(url);
          // var img = document.getElementById('img');
          // img.setAttribute('src', url);
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
        });
    },
    copyString(copyText) {
      // variable content to be copied
      let input = document.createElement("input");
      input.setAttribute("type", "text");
      input.value = copyText;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    },
    copyFileLink(path) {
      var x = this;
      if (path) {
        storage
          .ref()
          .child(path)
          .getDownloadURL()
          .then((url) => {
            x.copyString(url);
          })
          .catch((error) => {
            alert(error);
          });
      }
    },
  },
  watch: {
    //   'users.filters.approvedAccount'(){
    //       this.getUserData();
    //   }
  },
  computed: {
    pathArray() {
      return this.storage.currentPath.split("/");
    },
    numFilesSelected() {
      return Object.keys(this.storage.selected).length;
    },
    userProfile() {
      return this.$store.state.userProfile;
    },
    isAdmin() {
      if (_.includes(this.userProfile.permissions, "admin")) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    var x = this;
    //   Setup
    this.listFiles();
    //   x.$set(x.storage, 'selected', new Set());
    //   this.$set(x.storage, 'selected', 'hi');
    // Handle files
    const fileSelector = document.getElementById("file-selector");
    fileSelector.addEventListener("change", (event) => {
      const fileList = event.target.files,
        fileListLen = fileList.length;
      
      for(let i=0;i<fileListLen;i++){
        x.uploadFile(fileList[i], fileList[i].name);
      }
      // fileList.forEach((item) => {
      //   x.uploadFile(item, item.name);
      // });
    });

    // Handle key press
    // const files = document.getElementById("files");
    window.addEventListener("keyup", (event) => {
      switch (event.key) {
        case "Delete":
          x.deleteSelected();
          break;
      }
      // console.log(event.key);
    });
  },
};
</script>
<style lang="scss">
.folders,
.files {
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  grid-gap: 16px;
}
.folders > * {
  display: grid;
  grid-gap: 8px;
  align-items: center;
  grid-auto-flow: column;
  justify-content: flex-start;
  padding: 10px 16px;
  width: 190px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
  border: 2px solid transparent;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.folders .material-icons {
  color: inherit;
}
.folders > *:hover {
  border: 2px solid #555;
  color: #555;
}
.files > * {
  padding: 10px 16px;
  width: 190px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 7%);
  border: 2px solid transparent;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.files > *:hover {
  border: 2px solid #555;
}
.files .active,
.folders .active {
  border: 2px solid #0068ff;
}
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  user-select: none;
}
.breadcrumbs > * {
  padding: 2px 8px;
  border-radius: 5px;
  background-color: #d0e6f5;
  color: #2facee;
  margin: 4px;
  font-size: 12px;
  cursor: pointer;
}
</style>
<style lang="scss">
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
[el="tabs"] {
  user-select: none;
}
[el="table"],
[el|="table"] {
  --control-border: 0px;
}
[el="table"] tbody tr {
  height: 46.4px;
  /* content-visibility:auto;
    contain-intrinsic-size:1px 46.4px; */
}
[el="table"] tr:hover,
[el|="table"] tr:hover,
[el="table"] tr.active {
  /* box-shadow: 0 0 0 1px var(--color-sky); */
  outline: 1px solid var(--color-sky);
  outline-offset: -1px;
}
[el="table"] tr.active {
  background-color: var(--color-sky-5);
}
td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
thead th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 1px 0 var(--color-25);
}
.table-action thead th {
  box-shadow: 0 1px 0 var(--color-25), 0 1px 0 var(--color-25) inset;
}
.dynamic-table1 {
  display: grid;
  background: #ccc;
  height: 550px;
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-template-rows: max-content 1fr;
  grid-gap: 1px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.dynamic-table1.selected {
  grid-template-columns: 1fr 750px;
}
.dynamic-table1 > * {
  background: #fff;
  position: relative;
}
.search .material-icons {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-40);
  transition: 0.2s;
  -webkit-text-fill-color: currentColor;
}
.search:hover .material-icons,
.search:focus .material-icons {
  color: var(--color-sky);
}
.close {
  color: var(--color-40);
  -webkit-text-fill-color: currentColor;
  cursor: pointer;
  transition: 0.2s;
}
.close:hover {
  color: var(--color-sky);
}
.v-center {
  align-items: center;
}
tr.bold > td {
  font-weight: var(--font-medium);
}
.table-action th:first-child,
.table-action tr td:first-child {
  padding-left: 54px;
}
.table-action tr td:first-child {
  position: relative;
}
.table-action th:first-child .material-icons,
.table-action tr td:first-child .material-icons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
}

.thumbnail {
  width: 100%;
  height: 90px;
  object-fit: contain;
  opacity: 0.07;
  object-position: center;
}
.status {
  --size: 20px;
  height: var(--size);
  width: var(--size);
  display: inline-block;
  position: absolute;
  transform: translateY(-50%) translateX(-100%);
}
.text-right {
  text-align: right;
}
.module input {
  padding: 4px 8px;
  border-radius: 3px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.07);
}
</style>
