<template>
  <Module ref="module">
    <div v-if="module.section == 'view request'" el="list sm">
      <div el="list-column-between">
        <h3 el>Request Details</h3>
        <span el="icon" class="material-icons" @click="$refs.module.toggle()"
          >close</span
        >
      </div>
      <div el="list xxsm">
        <p el><b>Description:</b> {{ module.data.description }}</p>
        <p el><b>Requested on:</b> {{ formatDate(module.data.createdOn) }}</p>
        <p el><b>Starts:</b> {{ formatDate(module.data.start) }}</p>
        <p el><b>Ends:</b> {{ formatDate(module.data.end) }}</p>
        <p el><b>Status:</b> {{ module.data.status }}</p>
        <p el><b>Requested by:</b> {{ module.data.name }}</p>
        <p el><b>Request type:</b> {{ module.data.type }}</p>
        <p el><b>Request ID:</b> {{ module.data.id }}</p>
        <p el><b>Approved by:</b> {{ module.data.approvedBy.name }}</p>
      </div>
      <!-- <div el="list-column" style="justify-content: end;">
            <div el="btn">close</div>
        </div> -->
    </div>
    <div v-show="module.section == 'edit request'" el="list sm">
      <div el="list-column-between">
        <h3 el>Edit Request</h3>
        <span el="icon" class="material-icons" @click="$refs.module.toggle()"
          >close</span
        >
      </div>
      <div el="list xsm">
        <Calendar ref="cal2" :set="module.data.start" />
        <label
          >Start time:
          <input
            type="time"
            class="form-input"
            v-model="requests.clubhouse.start"
          />
        </label>
        <label
          >End time:
          <input
            type="time"
            class="form-input"
            v-model="requests.clubhouse.end"
          />
        </label>
        <label
          >Description (required)
          <input
            type="text"
            class="form-input"
            v-model="requests.clubhouse.description"
            style="width: 100%"
          />
        </label>
      </div>
      <div el="list-column" style="justify-content: end">
        <div el="btn-2ry" @click="$refs.module.toggle()">Cancel</div>
        <div el="btn" @click="updateRequest(module.data)">Save</div>
      </div>
    </div>
    <div v-if="module.section == 'delete account'" el="list sm">
      <div el="list-column-between">
        <h3 el>Delete My Account</h3>
        <span el="icon" class="material-icons" @click="$refs.module.toggle()"
          >close</span
        >
      </div>
      <div el="list xxsm">
        <p el>Are you sure you want to delete your account?</p>
      </div>
      <div el="list-column" style="justify-content: end">
        <div el="btn-2ry" @click="$refs.module.toggle()">Cancel</div>
        <div el="btn" @click="deleteAccount">Delete</div>
      </div>
    </div>
  </Module>
  <div id="module"></div>
  <div el="layout-ham fixed">
    <header el="header">
      <div el="logo">
        <span class="material-icons menu-btn" @click="toggleNavState"
          >menu</span
        >
        <div class="logo-text super-bold">Alphaleaf HOA</div>
        <!-- <svg id="mobileLogo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1099.64 249.7" el="logo" v-if="window.width > 600">
                        <path d="M137,48.71,13.29,15.56l33.14,123.7a102.78,102.78,0,0,0,102.79,76.11L188,214.05l22,22h23.81L211,213.26h0L38,40.29l32.24,8.43,82.65,82.65q.78-27.91,1.58-55.82a80.17,80.17,0,0,1,16.68,14.73l-1.71,57.65,42.31,42.3,1.33-38.73A102.79,102.79,0,0,0,137,48.71Z" class="logo-symbol"></path>
                        <path d="M346.83,144h-45l-9.19,21.36H271.41l44.2-96.23h17l44.19,96.23H356Zm-6.9-16.08L324.39,91.8l-15.54,36.09Z" class="logo-letters"></path>
                        <path d="M385.07,69.1h21.09v79.06h43v17.17H385.07Z" class="logo-letters"></path>
                        <path d="M462,69.1h43.66q15.95,0,24.8,7.7t8.85,21q0,13.11-8.92,20.88t-24.73,7.77H483.06v38.93H462Zm40.95,41.22q16.5,0,16.49-12.44T502.92,85.32H483.06v25Z" class="logo-letters"></path>
                        <path d="M639.16,69.1v96.23H618.48V124.92h-44.6v40.41H553.2V69.1h20.68V108h44.6V69.1Z" class="logo-letters"></path>
                        <path d="M722.82,144h-45l-9.19,21.36H647.4L691.59,69.1h17l44.2,96.23H732Zm-6.9-16.08L700.38,91.8l-15.54,36.09Z" class="logo-letters"></path>
                        <path d="M761.06,69.1h21.09v79.06h43v17.17H761.06Z" class="logo-letters"></path>
                        <path d="M838,69.1h66.36V85.45H858.5v22.71h43v16.49h-43V149h45.82v16.36H838Z" class="logo-letters"></path>
                        <path d="M981.9,144h-45l-9.19,21.36H906.48l44.2-96.23h17l44.19,96.23H991.09ZM975,127.89,959.46,91.8l-15.54,36.09Z" class="logo-letters"></path>
                        <path d="M1020.14,69.1H1085V85.72H1041V108h41.35v16.76H1041v40.55h-20.82Z" class="logo-letters"></path>
                    </svg>
                    <svg id="mobileLogo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.7 249.7" el="logo" v-else>
                        <path d="M137,48.71,13.29,15.56l33.14,123.7a102.78,102.78,0,0,0,102.79,76.11L188,214.05l22,22h23.81L211,213.26h0L38,40.29l32.24,8.43,82.65,82.65q.78-27.91,1.58-55.82a80.17,80.17,0,0,1,16.68,14.73l-1.71,57.65,42.31,42.3,1.33-38.73A102.79,102.79,0,0,0,137,48.71Z" class="logo-symbol"></path>
                    </svg> -->
      </div>
      <div></div>
      <div class="options" el="list-column" style="--gap: 16px">
        <!-- <span class="material-icons help-icon">help_outline</span>
                    <span class="material-icons notify-icon">notifications</span> -->
        <div class="dropdown" @click="toggleAccountDropdown">
          <!-- <img v-if="userProfile.profileImg != null" :src="userProfile.profileImg" alt="profile" class="profileImg" loading="lazy">
                        <img v-else src="../assets/img/profile.webp" alt="profile" class="profileImg" loading="lazy"> -->
          <span class="material-icons-outlined icon">account_circle</span>

          <div class="menu" v-if="layout.header.accountDropdown">
            <div>{{ userProfile.email }}</div>
            <!-- <hr class="none" el style="margin:8px 0;">
                            <div><span class="material-icons">account_box</span>My Profile</div>
                            <div><span class="material-icons">people</span>User Accounts</div>
                            <div><span class="material-icons">settings</span>Settings</div> -->
            <hr class="none" el style="margin: 8px 0" />
            <div @click="signOut">
              <span class="material-icons">exit_to_app</span>Sign Out
            </div>
          </div>
        </div>
      </div>

      <!-- <div el="input-list">
                    <label for="inputID" el="input">
                        
                        <input type="text" name="input" id="inputID" placeholder="Search..." el> 
                    </label>
                </div> -->
    </header>
    <aside el="aside" :class="{ collapse: layout.nav.collapse }">
      <!-- <div class="profile-container" el="list">
                    <img v-if="userProfile.profileImg != null" :src="userProfile.profileImg" alt="profile" class="navProfileImg" loading="lazy">
                    <img v-else src="../assets/img/profile.webp" alt="profile" class="navProfileImg" loading="lazy">
                    <p el="bold" v-if="!layout.nav.collapse">Julia Smith</p>
                </div> -->
      <div class="nav">
        <div
          el="list-column"
          :class="{
            'aside-nav-link': true,
            active: layout.nav.active == item.id,
          }"
          v-for="item in navMenu"
          @click="viewSection(item.id)"
          :key="item"
        >
          <span class="material-icons" v-if="item.icon != ''">{{
            item.icon
          }}</span>
          <span class="material-icons-outlined" v-else>{{
            item.iconOutlined
          }}</span>
          <span
            style="font-weight: var(--font-medium)"
            v-if="!layout.nav.collapse"
            >{{ item.label }}</span
          >
        </div>
      </div>
    </aside>
    <main el="main">
      <div
        el="indeterminate-progress"
        style="--height: 5px"
        v-if="layout.main.loading"
      ></div>

      <div
        el="list sm"
        style="padding: 32px"
        v-if="userProfile.approvedAccount == false"
      >
        <h3 el>Account Approval Required</h3>
        <p el>Your account must be approved by an administrator.</p>
      </div>
      <div style="min-height: 100%" v-else>
        <div
          el="list sm"
          style="padding: 32px"
          v-if="checkSection('dashboard')"
        >
          <h3 el class="super-bold">Dashboard</h3>
          <div class="cards">
            <div el="list sm" v-for="item in filteredCards" :key="item">
              <h3 el>{{ item.title }}</h3>
              <img
                :src="item.img"
                loading="lazy"
                alt="Article image"
                v-if="item.img != ''"
                class="dashboard-post-img"
              />
              <ViewPost :data="item.content" />
              <div style="height: 20px"></div>
            </div>
            <div
              el="list sm"
              style="overflow: auto"
              class="span-2"
              v-if="filteredPosts.length > 0"
            >
              <h3 el>Posts</h3>
              <div
                v-for="item in filteredPosts"
                :key="item"
                class="post"
                el="list sm"
              >
                <hr el />
                <img
                  :src="item.img"
                  loading="lazy"
                  alt="Article image"
                  v-if="item.img != ''"
                  class="dashboard-post-img"
                />
                <h3 el>{{ item.title }}</h3>
                <ViewPost :data="item.content" />
              </div>
              <div style="height: 20px"></div>
            </div>
            <div el="list sm">
              <h3 el>Helpful Links</h3>
              <div el="list xxsm">
                <div el="link" @click="viewSection('files')">Shared files</div>
                <hr el />
                <a
                  href="https://www.cdc.gov/coronavirus/2019-ncov/index.html"
                  rel="noopener nofollow"
                  target="_blank"
                  el="link"
                  >CDC General Information</a
                >
                <a
                  href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/community-mitigation-strategy.pdf"
                  rel="noopener nofollow"
                  target="_blank"
                  el="link"
                  >CDC – Strategies for Communities</a
                >
                <a
                  href="http://www.vdh.virginia.gov/"
                  rel="noopener nofollow"
                  target="_blank"
                  el="link"
                  >Virginia Department of Health</a
                >
                <a
                  href="https://www.loudoun.gov/5307/Coronavirus"
                  rel="noopener nofollow"
                  target="_blank"
                  el="link"
                  >Loudoun County Health Department</a
                >
                <a
                  href="https://www.loudoun.gov/5319/COVID-19-Questions-and-Answers"
                  rel="noopener nofollow"
                  target="_blank"
                  el="link"
                  >Loudoun County COVID-19 FAQ’s</a
                >
                <a
                  href="https://www.lcps.org/COVID19"
                  rel="noopener nofollow"
                  target="_blank"
                  el="link"
                  >LCPS Operations</a
                >
                <a
                  href="https://www.loudoun.gov/5321/County-Closures-Operating-Status"
                  rel="noopener nofollow"
                  target="_blank"
                  el="link"
                  >Loudoun County Closures & Operating Status</a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          el="list"
          style="padding: 32px; --gap: 24px"
          v-if="checkSection('users')"
        >
          <h3 el class="super-bold">Users</h3>
          <UserManagement />
        </div>
        <div
          el="list"
          style="padding: 32px; --gap: 24px"
          v-if="checkSection('requests')"
        >
          <h3 el class="super-bold">Clubhouse Reservations</h3>
          <div class="cards">
            <div el="list sm" class="span-2 fullHeight">
              <!-- <h3 el>Clubhouse Reservations</h3> -->
              <FullCalendar ref="calmod" />
              <!-- <p el>
                One week before your rental, please text Hien Aceron @
                703-587-0360 for how to retrieve keys for the clubhouse.
              </p> -->
            </div>
          </div>
        </div>
        <div
          el="list"
          style="padding: 32px; --gap: 24px"
          v-if="checkSection('posts')"
        >
          <h3 el class="super-bold">Posts</h3>
          <Posts />
        </div>
        <div el="list" v-show="checkSection('analytics')">
          <!-- <h3 el class="super-bold">Posts</h3> -->
          <!-- <Posts /> -->
          <iframe
            src="https://datastudio.google.com/embed/reporting/959c0fd0-e00b-4c00-88f3-a7d2754a7629/page/DinN"
            frameborder="0"
            style="width: 100%; height: calc(100vh - 65px)"
          ></iframe>
        </div>
        <div
          el="list"
          style="padding: 32px; --gap: 24px; min-height: 100%"
          v-if="checkSection('files')"
        >
          <h3 el class="super-bold">Files</h3>
          <Files />
        </div>
        <div
          el="list"
          style="padding: 32px; --gap: 24px; min-height: 100%"
          v-if="checkSection('messages')"
        >
          <h3 el class="super-bold">Messages</h3>
          <Messages />
        </div>
        <div
          el="list"
          style="padding: 32px; --gap: 24px; min-height: 100%"
          v-if="checkSection('emails')"
        >
          <h3 el class="super-bold">Send Email</h3>
          <div
            el="list"
            style="
              max-width: 400px;
              padding: 32px;
              background-color: #fff;
              overflow: hidden;
              box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
              border-radius: 5px;
            "
            class="emails"
          >
            <input
              type="text"
              placeholder="Subject..."
              v-model="emails.subject"
            />
            <select v-model="emails.to">
              <option value="admin">Admins Only</option>
              <option value="residents" v-if="isAdmin">All Residents</option>
            </select>
            <textarea
              cols="30"
              rows="5"
              placeholder="Message..."
              v-model="emails.text"
            ></textarea>
            <div>
              <div el="btn" @click="sendEmail">Send</div>
              <!-- <div el="btn" @click="queEmail">Que Email</div> -->
            </div>
          </div>
        </div>
        <div
          el="list"
          style="padding: 32px; --gap: 24px"
          v-if="checkSection('account')"
        >
          <div el="list-column-between">
            <h3 el class="super-bold">My Account</h3>
            <div>
              <div el="btn" @click="account.edit = true" v-if="!account.edit">
                Edit Account
              </div>
              <div el="btn" @click="updateAccount" v-else>Save</div>
            </div>
          </div>
          <!-- <img src="../assets/img/profile.webp" alt="profile" class="navProfileImg" loading="lazy"> -->
          <div v-if="account.edit" el="list sm">
            <!-- <div>
                              <div el="btn">Upload Image</div>
                          </div> -->
            <p el="bold">Contact Information</p>
            <div el="list xxsm">
              <div el="list-column">
                <p el>First Name</p>
                <input
                  type="text"
                  class="input-text"
                  v-model.trim="userProfile.firstName"
                />
              </div>
              <div el="list-column">
                <p el>Last Name</p>
                <input
                  type="text"
                  class="input-text"
                  v-model.trim="userProfile.lastName"
                />
              </div>
              <div el="list-column">
                <p el>Phone</p>
                <input
                  type="text"
                  class="input-text"
                  v-model.trim="userProfile.phone"
                />
              </div>
              <div el="list-column">
                <p el>Address</p>
                <input
                  type="text"
                  class="input-text"
                  v-model.trim="userProfile.address"
                />
              </div>
            </div>
          </div>

          <p el="bold" v-if="!account.edit">Contact Information</p>
          <div el="list xxsm" v-if="!account.edit">
            <p el>{{ userProfile.firstName }} {{ userProfile.lastName }}</p>
            <p el>{{ userProfile.email }}</p>
            <p el>{{ userProfile.phone }}</p>
            <p el>{{ userProfile.address }}</p>
          </div>
          <!-- <p el="bold">Actions</p>
                      <div el="list xxsm">
                        <div el="link">Update Name</div>
                        <div el="link">Update Email</div>
                        <div el="link">Update Phone Number</div>
                        <div el="link">Update Address</div>
                        <div el="link">Change Password</div>
                      </div> -->
          <div v-if="!account.edit">
            <!-- <div el="btn-2ry" @click="logAccountData">LOG Account</div> -->
            <div el="btn-2ry" @click="viewDeletePopup">Delete Account</div>
          </div>
        </div>
      </div>
    </main>
    <!-- <section el="section"></section> -->
    <!-- <section el="section" :class="{collapse: layout.section.collapse || layout.main.modules.selected == null}">
                <div el="indeterminate-progress" style="--height:5px;" v-if="layout.section.loading"></div>
                <div el="list" style="padding:32px;--gap:24px;" v-if="checkSubSection('modules')">
                    
                </div>
            </section> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { auth, db } from "../firebase";
import UserManagement from "@/components/UserManagement.vue";
import Posts from "@/components/Posts.vue";
import Files from "@/components/Files.vue";
import Module from "@/components/Module.vue";
import ViewPost from "@/components/ViewPost.vue";
import Calendar from "@/components/Calendar.vue";
import FullCalendar from "@/components/FullCalendar.vue";
import Messages from "@/components/Messages.vue";
import _ from "lodash";

export default {
  name: "Dashboard",
  components: {
    UserManagement,
    Posts,
    Files,
    Module,
    ViewPost,
    Messages,
    Calendar,
    FullCalendar,
  },
  data() {
    return {
      window: {
        width: 1366,
        timeout: null,
      },
      module: {
        label: "",
        show: false,
        section: "delete",
        data: {},
      },
      posts: {
        list: [],
      },
      account: {
        edit: false,
      },
      requests: {
        filters: {
          section: "user",
        },
        clubhouse: {
          description: "",
          start: "",
          end: "",
        },
        list: [],
      },
      emails: {
        subject: "",
        text: "",
        to: "admin",
      },
      layout: {
        header: {
          accountDropdown: false,
        },
        main: {
          loading: false,
        },
        section: {
          loading: false,
          active: "modules",
          collapse: true,
        },
        nav: {
          active: "dashboard",
          collapse: true,
          menu: [
            {
              id: "dashboard",
              label: "Dashboard",
              icon: "dashboard",
              permissions: [],
            },
            {
              id: "files",
              label: "Files",
              icon: "",
              iconOutlined: "folder",
              permissions: [],
            },
            {
              id: "users",
              label: "Users",
              icon: "account_box",
              permissions: ["admin"],
            },
            // {
            //     id:'messages',
            //     label:'Messages',
            //     icon:'',
            //     iconOutlined:'chat',
            //     permissions:[]
            // },
            {
              id: "requests",
              label: "Clubhouse",
              icon: "",
              iconOutlined: "home",
              permissions: [],
            },
            {
              id: "posts",
              label: "Posts",
              icon: "post_add",
              permissions: ["admin"],
            },
            {
              id: "emails",
              label: "Send Emails",
              icon: "",
              iconOutlined: "email",
              permissions: [],
            },
            // {
            //   id: "analytics",
            //   label: "Analytics",
            //   icon: "",
            //   iconOutlined: "summarize",
            //   permissions: ["admin"],
            // },
            {
              id: "account",
              label: "My Account",
              icon: "",
              iconOutlined: "settings",
              permissions: [],
            },
          ],
        },
      },
    };
  },
  methods: {
    toggleModule(val) {
      this.module.show = val;
    },
    toggleAccountDropdown() {
      this.layout.header.accountDropdown = !this.layout.header.accountDropdown;
    },
    viewSection(section) {
      if (this.window.width < 600) {
        this.toggleNavState();
      }
      this.layout.nav.active = section;
      this.layout.section.collapse = true;
    },
    checkSection(section) {
      return this.layout.nav.active == section ? true : false;
    },
    checkSubSection(section) {
      return this.layout.section.active == section ? true : false;
    },
    toggleNavState() {
      this.layout.nav.collapse = !this.layout.nav.collapse;
      if (this.window.width < 600) {
        this.layout.section.collapse = true;
      }
    },
    copyToClipboard(ID) {
      var range = document.createRange();
      range.selectNode(document.getElementById(ID));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      //alert("Text has been copied, now paste in the text-area");
      window.getSelection().removeAllRanges();
    },
    resizeWindowEvent() {
      var x = this;
      if (x.window.timeout != null) {
        clearTimeout(x.window.timeout);
      }
      x.window.timeout = setTimeout(function () {
        x.window.width = window.innerWidth;
      }, 100);
    },
    signOut() {
      auth
        .signOut()
        .then(function () {
          // Sign-out successful.
        })
        .catch(function (error) {
          console.log(error);
          // An error happened.
        });
    },
    // Firebase Methods
    deleteModule(docID) {
      console.log(docID);
      // var x = this;
      // x.layout.main.modules.selected = null;
      // db.collection("modules").doc(docID).delete().then(function() {
      //     console.log("Document successfully deleted!");
      //     Vue.delete(x.layout.main.modules.list, docID);
      // }).catch(function(error) {
      //     console.error("Error removing document: ", error);
      // });
    },
    // getModules(){
    //     db.collection("modules").get().then(function(doc) {
    //         if (doc.exists) {
    //             console.log("Document data:", doc.data());
    //         } else {
    //             // doc.data() will be undefined in this case
    //             console.log("No such document!");
    //         }
    //     }).catch(function(error) {
    //         console.log("Error getting document:", error);
    //     });
    // },
    async getModules() {
      // var x = this;
      this.layout.main.loading = true;
      const snapshot = await db.collection("modules").get();
      snapshot.docs.forEach(function (doc) {
        var data = doc.data();
        data.id = doc.id;
        // Vue.set(x.layout.main.modules.list, doc.id, data);
      });
      this.layout.main.loading = false;
    },
    logAccountData() {
      console.log(this.userProfile);
    },
    getPostsData() {
      var x = this;
      var posts = db.collection("posts");
      x.posts.list.splice(0, x.posts.list.length);
      posts
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var data = doc.data();
            data.id = doc.id;
            x.posts.list.push(data);
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    updateAccount() {
      var x = this;
      db.collection("users")
        .doc(auth.currentUser.uid)
        .update({
          firstName: x.userProfile.firstName,
          lastName: x.userProfile.lastName,
          phone: x.userProfile.phone,
          address: x.userProfile.address,
        })
        .then(() => {
          x.account.edit = false;
        });
    },
    deleteAccount() {
      var x = this;
      db.collection("users")
        .doc(auth.currentUser.uid)
        .delete()
        .then(() => {
          //logout
          console.log("deleted user!");
          x.signOut();
        })
        .catch((error) => {
          alert(error);
          console.error("Error removing document: ", error);
        });
    },
    submitRequest() {
      var x = this;
      let d = new Date(x.$refs.cal.selected);
      let ref = x.requests.clubhouse;
      let s = ref.start.split(":"),
        e = ref.end.split(":");
      d.setHours(s[0], s[1], 0);
      let start = d.getTime();
      d.setHours(e[0], e[1], 0);
      let end = d.getTime();

      if (ref.description == "") {
        alert("You must fill in details correctly");
        return;
      }

      db.collection("requests")
        .add({
          type: "clubhouse reservation",
          createdOn: Date.now(),
          start: start,
          end: end,
          description: ref.description,
          uid: auth.currentUser.uid,
          name: x.userProfile.firstName + " " + x.userProfile.lastName,
          status: "pending",
          approvedBy: {
            uid: null,
            name: "",
          },
        })
        .then(() => {
          //logout
          ref.description = "";
          ref.start = "";
          ref.end = "";
          console.log("added request!");
          x.getRequestData();
        })
        .catch((error) => {
          alert(error);
          console.error("Error removing document: ", error);
        });
    },
    updateRequest(item) {
      var x = this;
      let d = new Date(x.$refs.cal2.selected);
      let ref = x.requests.clubhouse;
      let s = ref.start.split(":"),
        e = ref.end.split(":");
      d.setHours(s[0], s[1], 0);
      let start = d.getTime();
      d.setHours(e[0], e[1], 0);
      let end = d.getTime();

      if (ref.description == "") {
        alert("You must fill in details correctly");
        return;
      }

      db.collection("requests")
        .doc(item.id)
        .update({
          start: start,
          end: end,
          description: ref.description,
        })
        .then(() => {
          //logout
          ref.description = "";
          ref.start = "";
          ref.end = "";
          x.$refs.module.toggle();
          x.getRequestData();
        })
        .catch((error) => {
          alert(error);
          console.error("Error updating document: ", error);
        });
    },
    getRequestData() {
      var x = this;
      var requests = db.collection("requests");
      x.requests.list.splice(0, x.requests.list.length);
      requests
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var data = doc.data();
            data.id = doc.id;
            x.requests.list.push(data);
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    formatDate(date) {
      let d = new Date(date);
      return d.toLocaleString("en-US");
    },
    formatTime(date) {
      let d = new Date(date);
      return d.toLocaleTimeString("en-US");
    },
    viewRequest(item) {
      this.module.data = item;
      this.module.section = "view request";
      this.$refs.module.toggle();
    },
    editRequest(item) {
      this.module.data = item;
      this.module.section = "edit request";
      this.$refs.module.toggle();
      this.requests.clubhouse.start = this.getTimeFromDate(item.start);
      this.requests.clubhouse.end = this.getTimeFromDate(item.end);
      this.requests.clubhouse.description = item.description;
    },
    getTimeFromDate(date) {
      let d = new Date(date),
        hrs = d.getHours() < 10 ? "0" + d.getHours() : d.getHours(),
        mins = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      return hrs + ":" + mins;
    },
    viewDeletePopup() {
      this.module.section = "delete account";
      this.$refs.module.toggle();
    },
    approveRequest(item) {
      db.collection("requests")
        .doc(item.id)
        .update({
          status: "approved",
        })
        .then(() => {
          item.status = "approved";
        })
        .catch((error) => {
          alert(error);
          console.error("Error removing document: ", error);
        });
    },
    denyRequest(item) {
      db.collection("requests")
        .doc(item.id)
        .update({
          status: "denied",
        })
        .then(() => {
          item.status = "denied";
        })
        .catch((error) => {
          alert(error);
          console.error("Error removing document: ", error);
        });
    },
    deleteRequest(id) {
      var x = this;
      db.collection("requests")
        .doc(id)
        .delete()
        .then(() => {
          x.getRequestData();
        })
        .catch((error) => {
          alert(error);
          console.error("Error removing document: ", error);
        });
    },
    sendEmail() {
      var x = this;
      if (x.emails.subject != "" && x.emails.text != "") {
        db.collection("emails")
          .add({
            subject: x.emails.subject,
            text: x.emails.text,
            to: x.emails.to,
            name: auth.currentUser.displayName,
            from: auth.currentUser.email,
            uid: auth.currentUser.uid,
            timestamp: Date.now(),
          })
          .then(() => {
            alert("Error sending email: unknown error");
            // alert("Sent successfully!");
            x.emails.subject = "";
            x.emails.text = "";
          })
          .catch((error) => {
            alert(error);
            console.error("Error sending email: ", error);
          });
      } else {
        alert("You must fill in all fields to send an email");
      }
    },
    queEmail() {
      let msg = {
        to: ["wesley@burkedesigns.biz"],
        message: {
          subject: "Hello from Firebase!",
          text: "This is the plaintext section of the email body.",
          html: "This is the <code>HTML</code> section of the email body.",
        },
      };

      db.collection("mail")
        .add(msg)
        .then(() => {
          alert("Sent successfully!");
        })
        .catch((error) => {
          alert(error);
          console.error("Error sending email: ", error);
        });
    },
    applyFilter(section) {
      switch (section) {
        case "user":
          this.requests.filters.section = "user";
          break;
        default:
          this.requests.filters.section = "residents";
      }
      // this.posts.section=section;
      // this.posts.selected = null;
    },
  },
  computed: {
    userProfile() {
      return this.$store.state.userProfile;
    },
    isAdmin() {
      return _.includes(this.userProfile.permissions, "admin");
    },
    navMenu() {
      var x = this;
      // return this.layout.nav.menu;
      return _.filter(this.layout.nav.menu, function (item) {
        if (x.userProfile == null) {
          return false;
        } else {
          let permissionLen = item.permissions.length,
            validPermissions = true,
            userPermissions = x.userProfile.permissions;
          // console.log(userPermissions);
          if (permissionLen > 0) {
            _.forEach(item.permissions, function (val) {
              if (_.includes(userPermissions, val) == false) {
                // if(x.userProfile.permissions[val] == false){
                validPermissions = false;
              }
            });
            return validPermissions;
          } else {
            return true;
          }
        }
      });
    },
    filteredPosts() {
      var x = this;
      let posts = _.orderBy(x.posts.list, ["createdOn"], ["desc"]);
      return _.filter(posts, function (item) {
        return (
          item.status == "published" &&
          _.includes(item.tags, "residents") &&
          !_.includes(item.tags, "card")
        );
      });
    },
    filteredCards() {
      var x = this;
      let posts = _.orderBy(x.posts.list, ["createdOn"], ["desc"]);
      return _.filter(posts, function (item) {
        return (
          item.status == "published" &&
          _.includes(item.tags, "residents") &&
          _.includes(item.tags, "card")
        );
      });
    },
    filteredRequests() {
      var x = this;
      let requests = _.orderBy(x.requests.list, ["createdOn"], ["desc"]);
      if (x.requests.filters.section == "user") {
        return _.filter(requests, function (item) {
          return item.uid == auth.currentUser.uid;
        });
      } else {
        return requests;
      }
    },
  },
  watch: {
    // userProfile(val){
    //   console.log(val);
    // }
  },
  mounted() {
    var x = this;
    x.window.width = window.innerWidth;
    window.addEventListener(
      "resize",
      function () {
        x.resizeWindowEvent();
      },
      { passive: true }
    );

    //var user = auth.currentUser;
    //x.user.profileImg = user.;
    auth.onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        var data = {
          uid: auth.currentUser.uid,
          email: auth.currentUser.email,
          emailVerified: auth.currentUser.emailVerified,
          displayName: auth.currentUser.displayName,
          phoneNumber: auth.currentUser.phoneNumber,
          photoURL: auth.currentUser.photoURL,
        };
        x.$store.commit("setCurrentUser", data);
        x.$store.dispatch("fetchUserProfile");
      } else {
        // User is signed out.
        x.$router.push("/login");
      }
    });
    // auth.onAuthStateChanged(function(user) {
    //     if (user) {
    //         // User is signed in.
    //         x.user = user;
    //         // var displayName = user.displayName;
    //         // var email = user.email;
    //         // var emailVerified = user.emailVerified;
    //         // var photoURL = user.photoURL;
    //         // var isAnonymous = user.isAnonymous;
    //         // var uid = user.uid;
    //         // var providerData = user.providerData;
    //         // ...
    //     } else {
    //         // User is signed out.
    //         // ...
    //         x.$router.push('/login');
    //     }
    // });

    this.getPostsData();
    this.getRequestData();
  },
};
</script>
<style lang="scss">
.form-input {
  font-size: var(--font-p);
  font-weight: var(--font-regular);
  border-radius: 3px;
  border: 0;
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.07);
}
.input-text {
  font-size: var(--font-p);
  font-weight: var(--font-regular);
  padding: 4px;
  border-radius: 3px;
  border: 0;
}
[el~="layout-ham"] {
  --layout-columns: [header-start aside] max-content [main] 1fr [section]
    max-content [header-end];
  --layout-rows: [header] minmax(65px, max-content) [aside main section] 1fr;
}
/* Header */
[el="header"] {
  --bg-color: var(--color-ocean);
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: 16px;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}
[el="header"] [el="logo"] {
  fill: #fff;
  height: 35px;
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: column;
  align-items: center;
}
/* .material-icons{
    color:var(--color);
    -webkit-text-fill-color: currentColor;
    transition: background-color 0.2s;
} */
[el="aside"] {
  width: 200px;
  padding-top: 30px;
  display: grid;
  grid-gap: 24px;
  align-content: flex-start;
  transition: width 0.2s;
  --bg-color: rgb(255 255 255 / 100%);
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}
[el="aside"].collapse {
  width: 55px;
}
[el="section"] {
  width: 400px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
  transition: width 0.2s;
}

[el="main"] {
  --bg-color: var(--color-7);
}
@media (max-width: 600px) {
  [el="aside"],
  [el="section"] {
    width: 100vw;
  }
  [el="aside"].collapse {
    width: 0px;
  }
}
[el="section"].collapse {
  width: 0;
}
[el="header"] > .options {
  align-items: center;
}
[el="header"] [el="logo"] .material-icons,
.help-icon,
.notify-icon {
  color: hsla(0, 0%, 100%, 0.65);
  -webkit-text-fill-color: currentColor;
  transition: background-color 0.2s;
  cursor: pointer;
}
[el="header"] [el="logo"] .material-icons:hover,
.help-icon:hover,
.notify-icon:hover {
  color: hsla(0, 0%, 100%, 1);
}
.menu-btn {
  padding: 3px 5px;
  border-radius: 3px;
  transition: background-color 0.2s;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.menu-btn:hover {
  background-color: hsla(0, 0%, 0%, 0.25);
}
.profile-container {
  display: grid;
  justify-content: center;
  justify-items: center;
}
.profileImg,
.navProfileImg {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  background-color: #f1f1f1;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.65);
  cursor: pointer;
}
.navProfileImg {
  --size: 112px;
  transition: 0.2s;
}
[el="aside"].collapse .navProfileImg {
  --size: 32px;
}
.dropdown {
  position: relative;
}
.dropdown .menu {
  position: absolute;
  right: 0;
  background-color: #fff;
  top: 100%;
  z-index: 5;
  min-height: 100px;
  width: 250px;
  box-shadow: 0 3px 8px hsla(0, 0%, 0%, 0.05);
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dropdown .menu > *:not(.none) {
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  justify-content: start;
}
.dropdown .menu > * .material-icons {
  font-size: 24px;
}
.dropdown .menu > *:not(.none):hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.logo-text {
  color: #fff;
  -webkit-text-fill-color: currentColor;
}
.aside-nav-link {
  padding: 10px 16px;
  font-weight: var(--font-medium) !important;
  --color: var(--color-65);
  transition: color 0.2s;
  cursor: pointer;
  align-items: center;
  --gap: 16px;
  user-select: none;
  white-space: nowrap;
}
.aside-nav-link.active {
  --color: var(--color-ocean);
  background-color: rgba(0, 0, 0, 0.07);
  box-shadow: 5px 0px 0px var(--color-ocean) inset;
}
.aside-nav-link:hover {
  background-color: rgba(0, 0, 0, 0.07);
  color: var(--color-ocean);
}
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 16px;
}
.card {
  padding: 16px;
  border-radius: 5px;
  --bg-color: #fff;
  box-shadow: 0 0 0 1px transparent inset, 0 3px 8px hsla(0, 0%, 0%, 0.05);
  --gap: 16px;
  transition: box-shadow 0.2s;
  user-select: none;
  cursor: pointer;
  max-height: 700px;
}
.card:hover,
.card.active {
  box-shadow: 0 0 0 1px var(--color-mist) inset, 0 3px 8px hsla(0, 0%, 0%, 0.05);
}
.card.active {
  --bg-color: var(--color-sky-3);
}
.card img {
  height: 65px;
  width: 65px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  background-color: #fff;
}

/* .floating-section{
    position:fixed;
    background-color:#fff;
    width:calc(100vmin - 64px);
    height:calc(100vh - 108px);
    max-width:1000px;
    box-shadow:0 3px 15px rgba(0,0,0,0.15);
    z-index:10;
    border-radius:5px;
    left:50%;
    top:50%;
    transform:translateX(-50%) translateY(-50%);

} */
.module-content > * {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
}
.super-bold {
  font-weight: 900 !important;
}
</style>
<style lang="scss">
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 16px;
}
.cards .span-2 {
  grid-column: span 2;
}
@media (max-width: 1040px) {
  .cards .span-2 {
    grid-column: span 1;
  }
}
.cards > * {
  --bg-color: #fff;
  padding: 16px;
  border-radius: 3px;
  // --border: 1px solid rgb(0 0 0 / 13%);
  box-shadow: 0 3px 10px rgb(0 0 0 / 8%);
  max-height: 512px;
  height: 100%;
  overflow: auto;
}
.cards > .fullHeight {
  max-height: unset;
}
.dashboard-post-img {
  width: 100%;
  max-width: 600px;
  max-height: 300px;
  object-fit: cover;
  object-position: center;
}
.icon {
  color: hsla(0, 0%, 100%, 0.65);
  transition: 0.2s;
  cursor: pointer;
}
.icon:hover {
  color: #fff;
}
.emails input,
.emails textarea,
.emails select {
  border: 0;
  background-color: hsl(0deg 0% 0% / 5%);
  padding: 5px 10px;
  border-radius: 3px;
  font-family: poppins;
  font-size: 14px;
  transition: 0.3s;
  border: 2px solid transparent;
}
.emails input:focus,
.emails textarea:focus,
.emails select:focus {
  border: 2px solid rgba(0, 0, 0, 0.15);
}
</style>
